import axios from 'axios';

let currentController = null;
export default {
  async getEventsWithPagination(params) { 
    if (currentController) {
      currentController.abort();
    }
    // Создаём новый AbortController
    currentController = new AbortController();
    const { signal } = currentController;
    const response = await axios.post('/api/ExtEvents/GetEventsWithPagination', params, { signal });
    currentController = null;
    return response;
  },
  getEventCard: (id) => axios.get('/api/ExtEvents/GetEventCard/', { params: { id } }),
  delete(id) {
    return axios.delete('/api/ExtEvents/Delete/', { params: { id } });
  },
  restore: (id) => axios.delete('/api/ExtEvents/Restore/', { params: { id } }),
  updateEvent: (event) => axios.put(`/api/ExtEvents/UpdateEvent/${event.id}`, event),
  createEvent: (eventCard) => axios.post('/api/ExtEvents/CreateEvent', eventCard),
  // getEventsWithPagination: (params) => axios.post('/api/UserDataEvents/GetUserDataEventsWithPagination', params),
  // getEventCard: (id) => axios.get('/api/UserDataEvents/GetEventCard/', { params: { id } }),
  // delete(id) { return axios.delete('/api/UserDataEvents/Delete/', { params: { id } }); },
  // restore: (id) => axios.post('/api/UserDataEvents/Restore/', { id }),
  // updateEvent: (event) => axios.put(`/api/UserDataEvents/UpdateEvent/${event.id}`, event),
  // createEvent: (eventCard) => axios.post('/api/UserDataEvents/CreateUserDataEvent', eventCard),
};
